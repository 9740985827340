.product-card {

    min-height: 150px;
    background-color: var(--branco);
    border-radius: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  
}

article.product-content {

    padding: 0px 20px;
    flex: 1 1 130px

}

h4.product-title {

    font-size: 18px;
    margin: 10px 0px 0px 0px;
    text-transform: uppercase;

}

p.product-description {

    line-height: 1em;
    margin: 5px 0px 10px 0px;

}

p.product-first_price {

    color:var(--cinza-escuro);
    margin: 0px;
    text-decoration: line-through;

}

h4.product-last_price {

    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 18px
}

@media (max-width: 600px) {

    h4.product-title {

        font-size: 14px;
        margin: 5px 0px 0px 0px;
    
    }

    p.product-description {

        font-size: 12px;
        margin: 5px 0px;

    }

    p.product-first_price {

        font-size: 14px;

    }

    h4.product-last_price {

        font-size: 16px;
    } 

}

@media (max-width: 390px){

    div.product-card {

        flex-direction: column;
      
    }

    h4.product-title {

        font-size: 16px;
        margin: 5px 0px 0px 0px;
    
    }

    p.product-description {

        font-size: 14px;
        margin: 10px 0px;

    }

    p.product-first_price {

        font-size: 16px;

    }

    h4.product-last_price {

        font-size: 18px;
    } 

}
section#main-contact {

    margin-top:-30px;
    padding: 40px 10px 40px 10px;
    border-radius: 0px 0px 30px 30px;
    background-color: var(--cinza-escuro);


}

section#main-contact > article {

    display: flex;
    flex-wrap: wrap;

}

div#contact-address {

    flex: 1 1 150px;
    margin-right: 10px;

}

div#contact-address h4 {

    text-transform: uppercase;
}

div#contact-address p {

    line-height: 1em;
    margin-bottom: 30px;

}

div#contact-social_media {

    flex: 1 1 100px;
    margin-left: 10px;

}

div#contact-social_media h4 {

    text-transform: uppercase;

}

div#social-icons__container {

    width: 70px;
    color: var(--preto);
    display: flex;
    justify-content: space-between;

}

div#social-icons__container > a {

    color: var(--preto);

}


@media (max-width: 768px) {

    section#main-contact {

        grid-column: 1/4;

    }

}

@media (max-width: 390px) {

    section#main-contact > article {

        flex-direction: column;
        text-align: center;
    
    }

    div#contact-address {

        margin-right: 0px;
    
    }

    div#contact-social_media {

        margin-left: 0px;
    
    }    

    div#social-icons__container {

        width: 100%;
        justify-content: center;
    }

    div#social-icons__container > * {

        margin: 0px 10px;
    }

}
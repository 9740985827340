main#fullitem-main {

    margin-top: 0px;

}

div#fullitem-header {

    min-height: 300px;
    color: var(--branco);
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    align-items: flex-end;

}

h1#fullitem-title {

    text-transform: uppercase;

}

p#fullitem-description {

    line-height: 1em;
    color: var(--preto);
    margin: 30px 0px;

}

h3#fullitem__size-title {

    margin-left: 20px;
    text-transform: uppercase;

}

section#fullitem__size-content {

    margin-bottom: 30px;
    padding: 10px 20px;
    background-color: var(--branco);
    border-radius: 20px;
}

div.fullitem__content {

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

}

div.fullitem__content > div > h3 {

    margin: 0px;
    text-transform: uppercase;

}

div.fullitem__content > div > p {

    margin: 0px;
    
}

div.fullitem__content > div > h2 {

    text-align: right;
    margin: 0px;
    
}

article > hr {

    border: var(--cinza-claro) 1px solid;
}

a.button-container {

    margin-bottom: 30px;

}
/* Ajustes Gerais */

html {
  scroll-behavior: smooth;
}

a {

  color: unset;
  text-decoration: unset;
  
}

/* Cores do projeto */

:root {

  --vermelho-base: #dd3c22;
  --vermelho-claro: #fa5a42;
  --cinza-claro: #f1f1f1;
  --cinza-escuro: #dcdcdc;
  --status-verde: #39ff14;
  --preto: #040707;
  --branco: #fff;

}


/* Classes de uso geral */

.grid {
  display: grid;
  grid-template-columns: minmax(10px, 1fr) minmax(280px, 720px) minmax(10px, 1fr);
}

.grid-center {

  grid-column: 2/3;

}

.grid-full {

  grid-column: 1/4;

}

.inner-grid {

  display: grid;
  grid-template-columns: 1fr 10fr 1fr;

}

/* Ajustes do conteúdo principal */

main.grid-center {

  margin-top: 70px;
  background-color: var(--cinza-claro);
  border-radius: 20px;
  z-index: 10;

}

@media (max-width: 768px) {

  main.grid-center {

    grid-column: 1/4;
    
  }
}


@media (max-width: 500px) {

  .inner-grid {

    grid-template-columns: .5fr 10fr .5fr;
  
  }

}
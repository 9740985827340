section#banner-section {

    display: flex;
    justify-content: center;

}


section#banner-section img {

    width: 100%;
    margin-top: -70px;

}


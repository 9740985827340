a.menu-item {

    width: 150px;
    font-size: 14px;
    font-weight: 600;
    color: var(--preto);
    text-transform: uppercase;
    text-decoration: none;
    background-color: var(--branco);
    margin: 0px 5px;
    padding: 15px 20px;
    border: 0px;
    border-radius: 25px;
    display: flex;
    justify-content: space-evenly;

}

a.menu-item-gray {

    background-color: var(--cinza-claro);
    
}

a.menu-item:hover {

    color: var(--branco);
    background-color: var(--vermelho-base);

}

a.menu-item:active {

    background-color: var(--vermelho-claro);
}

.menu-item__icon {

    margin-right: 5px;

}
div#payment__type-selector {

    text-align: center;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 25px;
    background-color: var(--branco);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

div#payment__type-selector > label {

    margin-right: 10px;
    flex: 1 1;
    display: flex;
    flex-wrap: nowrap;
 
}

#payment__type-selector > label:nth-child(1) {

    /* flex-basis: 60px; */
}

div#payment-content__conteiner {

    text-align: center;
    padding: 5px 0px 25px 0px;
    margin: 20px 0px;
    border-radius: 25px;
    background-color: var(--branco);

}

div#payment-content__conteiner > h3 {

    text-transform: uppercase;
    
}

div#payment-content__conteiner > div  {

    display: flex;
    justify-content: center;
    align-items: center;

}

div#payment-content__conteiner > div >  h3 {

    margin: 0px 10px 0px 0px;
    text-transform: capitalize;
    font-weight: 500;

}

div#payment-content__conteiner > div >  h4 {

    margin: 0px;
    font-weight: 400;

}

form input.form-input {

    width: 100px;
    text-align: center;
    font-size: 1em;
    padding: 5px 10px;
    margin: 10px 0px;
    border: 1px var(--cinza-escuro) solid;
    background-color: var(--cinza-claro);
    border-radius: 25px;

}

div#buttons-container {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    
}

@media (max-width: 425px) {

    div#payment__type-selector > label {

        flex-direction: column;
        align-items: center;
    }
    
}
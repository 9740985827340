div#order-header__title {

    background-color: var(--vermelho-base);
    border-radius: 20px;

}


div#order-header__title > h1 {

    font-size: 24px;
    margin: 10px 0px;
    color: var(--branco);
    text-transform: uppercase;

}

h3.order-title {

    padding: 10px 0px;
    border-radius: 25px;
    text-transform: uppercase;
    background-color: var(--branco);
}

h3.order-title > span.order-title__number {

    color: var(--branco);
    padding: 10px 17px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: var(--vermelho-base);

}

section.content_section{

    margin-bottom: 30px;
    padding: 10px 20px;
    background-color: var(--branco);
    border-radius: 20px;

}


div.item-description > p.variarion {

    text-transform: capitalize;
    margin-top: 5px;

}

div.item-check > input[type='checkbox'] {

    border-radius: 50%;
}

form#dadosPessoais {

    display: flex;
    flex-direction: column;
}

form#dadosPessoais > label {

    text-transform: uppercase;
    font-weight: 600;
    margin-left: 20px;
}

form#dadosPessoais > input {

    font-size: 1em;
    padding: 10px 20px;
    margin: 10px 0px;
    border: unset;
    border-radius: 25px;

}

form#dadosPessoais > input:focus {

    border: 0.5px var(--vermelho-base) solid;
}

form#dadosPessoais > input:focus-visible {

    outline: -webkit-focus-ring-color var(--vermelho-base) 1px;
}

form#dadosPessoais > button {

    color: var(--branco);
    text-transform: uppercase; 
    background-color: var(--vermelho-base);
    padding: 15px 20px;
    margin-bottom: 20px;
    border: 0px;
    border-radius: 25px;
    align-self: center;

}

form#dadosPessoais > button:active {

    background-color: var(--vermelho-claro);
    
}

@media (max-width: 425px) {

    h3.order-title {

        padding: 5px 0px;
    }

    h3.order-title > span.order-title__number {

        padding: 5px 12px;

    }
}
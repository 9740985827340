#app-header > div {

    margin: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

img#header-logo {

    flex: 1 1 40px;
    max-width: 120px;
}

div.status-content {

    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
}

div.open-status {

    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

div.open {
    
    background-color: var(--status-verde);
    
}

div.close {
    
    background-color: var(--vermelho-base);

}

h4.status-title {

    text-transform: uppercase;

}

div#order-link {

    text-align: center;
}

div#order-link h6 {

    margin: 0px;
    text-transform: uppercase;
}

@media(max-width: 425px) {


    /* #app-header {

        grid-column: 1/4;
    } */

    img#header-logo {

        max-width: 100px;

    }

    div.status-content > h4.status-title {

        font-size: 12px;

    }

    div#order-link {

        margin-left: 10px;
        
    }

}
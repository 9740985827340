main#order-main {

    padding-bottom: 40px;
    margin-top: 0px;
    
}

/* Ajustes do mostrador de etapa */

div#steps-titles {

    padding: 10px 0px;
    background-color: var(--branco);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

div#steps-titles > h3.step__order-title {

    font-size: 16px;
    margin: 0px 15px 0px 0px;
    text-transform: uppercase;
    
}

div#steps-titles > h3.step__order-title > span.order-title__number {
    
    color: var(--branco);
    padding: 10px 17px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--cinza-escuro);

}

div#steps-titles > h3.step__order-title > span.order-title__number-active {
    
    color: var(--branco);
    padding: 10px 17px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--vermelho-base);

}

@media(max-width: 425px) {

    div#steps-titles {

        height: 100px;
        padding: 15px 0px 0px 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
    }

    div#steps-titles > h3.step__order-title {

        /* width: 150px; */
        font-size: 14px;
        flex: 1 1;
        
    }

    div#steps-titles > h3.step__order-title > span.order-title__number {

        padding: 5px 11px;

    }

    div#steps-titles > h3.step__order-title > span.order-title__number-active {

        padding: 5px 11px;

    }

    
}


/* Ajustes do container com os dados prévios do usuário */

section#usuario_dados-previos {

    margin: 30px

}

section#usuario_dados-previos > h3 {

    text-align: center;
    
}


/* Ajuste de títulos das seções do formulário */


div.order-section__title-container {

    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

div.order-section__title-container > div > * {

    margin: 0px;
}

div.order-section__title-container > div > h3 {

    text-transform: uppercase;
}

div.order-section__title-container > h4 {

    color: var(--branco);
    text-transform: capitalize;
    font-weight: 400; 
    background-color: var(--vermelho-base);
    padding: 15px 40px;
    margin: 0px;
    border: 0px;
    border-radius: 25px;

}

@media (max-width: 425px) {
    
    div.order-section__title-container > div > h3{

        font-size: 16px;

    }

    div.order-section__title-container > h4 {

        font-size: 14px;
        font-weight: 600;
        padding: 11px 15px;

    }
}

@media (max-width: 320px) {
    
    div.order-section__title-container > div > h3{

        font-size: 14px;
        
    }

    div.order-section__title-container > h4 {

        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 11px 10px;

    }
}

/* Ajuste das descrições e seletores do formulário */

div.order-section__selecter-container {

    padding: 20px 20px;
    background-color: var(--branco);
    border-radius: 25px;

}

div.order-section__selecter-container > article > div {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

label.selecter-container__description {

    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

label.selecter-container__description > h3 {

    text-transform: uppercase;
    margin: 0px;
}

label.selecter-container__description > p {

    text-transform: capitalize;
    margin: 0px;
}

div.order-section__selecter-container > article > hr {

    border: var(--cinza-claro) 1px solid;
    margin-bottom: 15px;
    
}
label.selecter__description-price {

    max-width: 70%;
}

label.selecter__description-price > h3 {

    text-transform: uppercase;
    margin: 0px;
}

label.selecter__description-price > p{

    margin: 0px;
}

label.selecter__description-price > p.price{

    text-transform: capitalize;
    margin: 0px;
}

div.beverage-size__selecter {

    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 425px) {

    label.selecter-container__description > h3 {
        
        font-size: 16px;
    }

    label.selecter__description-price {

        width: 60%;

    }

    label.selecter__description-price > h3 {

        font-size: 16px;

    }

    #beverage-section > div.order-section__selecter-container > article > div {

        flex-direction: column;
        text-align: center;
        width: 100%;

    }

    #beverage-section > div.order-section__selecter-container > article > div > label {

        width: 100%;
        max-width: unset;
    }
    #beverage-section > div.order-section__selecter-container > article > div > div {

        width: 100%;

    }

} 

/* Ajustes de estilo do contador de produtos */


div.item-counter__container {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

div.item-counter__container > h3 {

    font-size: 1.5em;
    color: #b5b3b3;
    margin: 0px 5px;
    padding: 0px 5px;

}

div.item-counter__container > button {

    font-size: 1.5em;
    color: var(--vermelho-base);
    background-color: unset;
    text-transform: uppercase; 
    padding: 0px 7px;
    border: 0px;
    border-radius: 0px;

}

div.item-counter__container > button:nth-child(1) {

    padding: 0px 10px;

}


div.item-counter__container > button:active {

    color: var(--branco);
    background-color: var(--vermelho-claro);
    border-radius: 50%;


}

h6.size-warning {

    color: var(--vermelho-base);

}


/* Ajustes do modal de novo item */

div#modal-background {

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 100;

}

div#new-item__modal {

    width: 50%;
    min-width: 200px;
    max-width: 400px;
    /* height: 300px; */
    padding: 30px;
    border-radius: 20px;
    background-color: var(--branco);
    box-shadow: 0px 0px 10px var(--preto);
    position: absolute;
    z-index: 100;
    top: calc(50vh/2);
    left: calc(70vw/2);

    text-align: left;

}

div#new-item__modal > h2 {

    font-size: 18px;
    text-transform: uppercase;
}

div.modal__resume-list {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

div.modal__resume-list h3 {

    margin: 0px;
    font-size: 18px;
    text-transform: uppercase;
}

div.modal__resume-list h2 {

    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

div.modal__resume-list p {

    margin: 0px;

}

div.modal-cta {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

div.modal-cta > h2 {

    font-size: 18px;
    text-transform: uppercase;

}


@media(max-width: 1024px) {

    div#new-item__modal{

        top: calc(50vh/2);
        left: calc(50vw/2);

    }

}

@media(max-width: 425px) {

    div#new-item__modal{

        top: calc(50vh/2);
        left: calc(50vw/3);

    }

}

@media(max-width: 320px) {

    div#new-item__modal{

        top: calc(50vh/2);
        left: calc(50vw/4);

    }

}
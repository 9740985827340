footer#main-footer {

    text-align: center;

}

img#logo-arara {

    margin: 40px 0px;

}

img#logo-footer {

    width: 100%;
    margin-bottom: -5px;
}

@media (min-width: 720px) {

    footer#main-footer {

        grid-column: 2/3;

    }
}